.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  /* background-color: DodgerBlue; */
}

/* .flex-container > div { */
  /* background-color: #f1f1f1; */
  /* width: 100px; */
  /* margin: 10px; */
  /* text-align: center; */
  /* line-height: 75px; */
  /* font-size: 30px; */
/* } */


table , td {
  border: 1px solid black;
  border-collapse: collapse;
}



.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-item-left {
  padding: 10px;
  flex: 70%;
}

.flex-item-right {
  padding: 10px;
  flex: 30%;
}
.flex-right-item {
  flex: 50%;
  padding: 5px;
}

.item-header {
  background-color: #9c2b63;
  padding:12px;
  color:#FFF;
}

.flex-container > .item {
  flex: 50%;
  padding: 5px;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.center{
  text-align: center;
}

.bold{
  font-weight: bold;
}